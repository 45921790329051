import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { config, useTrail, animated } from '@react-spring/web'
import { useMedia } from 'react-use-breakpoints'
import Image from '../utils/Image'
import { TilesStyle } from '../styles/elements'
import { touchMediaCondition } from '../styles/utils'

const Tiles = (props) => {
  const {
    wp: { tiles },
    newsPage,
  } = props.data
  return (
    <TilesStyle number={tiles.length + 1}>
      <AllTiles tiles={tiles} newsPage={newsPage} />
    </TilesStyle>
  )
}

export const concatTilesWithNews = (tiles, news) => {
  const newsPage = {
    node: {
      title: news.title,
      slug: news.slug,
      id: news.id,
      featuredImage: news.featuredImage,
    },
  }
  return [newsPage].concat(tiles)
}

export const AllTiles = ({ newsPage, tiles, setShow, nav = false }) => {
  const [showGrid, setShowGrid] = useState(false)
  const tilesWithNews = concatTilesWithNews(tiles, newsPage)
  const mediaQuery = useMedia(`${touchMediaCondition}, (max-width: 680px)`)
  const trail = useTrail(tilesWithNews.length, {
    config: config.stiff,
    opacity: showGrid ? 1 : 0,
  })

  useEffect(() => {
    setShowGrid(true)
  }, [])

  return (
    <ul>
      {trail.map((trailStyle, i) => {
        return (
          <animated.li style={trailStyle} key={i} number={i}>
            {tilesWithNews[i].node.featuredImage.node &&
              !nav &&
              !mediaQuery && (
                <Image
                  file={tilesWithNews[i].node.featuredImage.node.localFile}
                  alt={`News-Image ${i} of: ${tilesWithNews[i].node.title}`}
                />
              )}
            <Link
              onClick={() => setShow && setShow(false)}
              to={`/${tilesWithNews[i].node.slug}`}
              dangerouslySetInnerHTML={{
                __html: `<span>${tilesWithNews[i].node.title}</span>`,
              }}
            />
          </animated.li>
        )
      })}
    </ul>
  )
}

export default Tiles
