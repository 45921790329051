import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/utils/Seo'
import Tiles from '../components/pages/Tiles'

export const TILES_QUERY = graphql`
  query TilesQuery {
    wp: allWpTile(filter: { slug: { ne: "donotdelete" } }) {
      ...tilesFragment
    }
    newsPage: wpPage(slug: { eq: "news" }) {
      ...newsPageFragment
    }
  }
`

export const tilesFragment = graphql`
  fragment tilesFragment on WpTileConnection {
    tiles: edges {
      node {
        title
        slug
        featuredImage {
          node {
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  height: 600
                  quality: 90
                  layout: FIXED
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
export const newsPageFragment = graphql`
  fragment newsPageFragment on WpPage {
    title
    slug
    id
    featuredImage {
      node {
        localFile {
          id
          childImageSharp {
            gatsbyImageData(
              width: 1400
              height: 1400
              quality: 90
              layout: FIXED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  return (
    <>
      <Seo {...props} />
      <Tiles {...props} />
    </>
  )
}

export default IndexPage
